import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import util from 'util';

const DisplayLinker = (props) => {

  useEffect(() => {
    window.open(`${props.match.path}`);
    window.open(`${props.match.path}signage`);
    window.open(`${props.match.path}smartsignage`);
  }, []);

  return (
    <div className="container">
    </div>
  )
}

export default DisplayLinker;