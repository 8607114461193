import React, { useMemo } from 'react';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import { Route } from 'react-router-dom';
import NavBar from '../Components/NavBar/NavBar';
import UserSlice from '../Slices/User';
import UserOnly from '../Components/Auth/UserOnly';
import GuestOnly from '../Components/Auth/GuestOnly';
import Login from '../Components/Auth/Login';
import Base from '../Components/Auth/Base';
import BackyardOrder from '../Components/BackyardOrder/BackyardOrder';
import BackyardOrderFin from '../Components/BackyardOrder/BackyardOrderFin';
import BackyardCheckIn from '../Components/BackyardCheckIn/BackyardCheckIn';
import { ToastProvider } from 'react-toast-notifications';
import BackyardOrderFinTab from '../Components/BackyardOrder/BackyardOrderFinTab';
import constClass from '../Constants/Constants';

const useActions = (actions, deps) => {
  const dispatch = useDispatch();
  return useMemo(
    () => {
      if (Array.isArray(actions)) {
        return actions.map(a => bindActionCreators(a, dispatch))
      }
      return bindActionCreators(actions, dispatch)
    },
    deps ? [dispatch, ...deps] : [dispatch]
  )
};

const BackApp = (props) => {
  const userActions = useActions(UserSlice.actions);
  const user = useSelector(state => state.User);

  const _userProps = { user, userActions, ...props };
  return (
    <div className='backyard-top'>
      <Route path={`${props.match.path}`} render={() =>
        <Base {..._userProps}>
          <NavBar {..._userProps} />
          <ToastProvider  placement="bottom-center">
          <Route render={() =>
            <UserOnly {..._userProps} >
              <Route exact path={`${props.match.path}`} >
                {/* <BackyardOrder {..._userProps} /> */}
                <BackyardOrderFinTab sts={constClass.STATUS.REG} {..._userProps} />
              </Route>
              <Route path={`${props.match.path}fin`} >
                <BackyardOrderFinTab sts={constClass.STATUS.FIN} {..._userProps} />
              </Route>
              <Route path={`${props.match.path}ccl`} >
                <BackyardOrderFinTab sts={constClass.STATUS.CCL} {..._userProps} />
              </Route>
              <Route path={`${props.match.path}abs`} >
                <BackyardOrderFinTab sts={constClass.STATUS.ABS} {..._userProps} />
              </Route>
              <Route path={`${props.match.path}checkin`} >
                <BackyardCheckIn {..._userProps} />
              </Route>
            </UserOnly>
          } />
          <Route render={() =>
            <GuestOnly {..._userProps} >
              <Route path={`${props.match.path}login`} render={() =>
                <Login {..._userProps} />
              } />
            </GuestOnly>
          } />
          </ToastProvider>
        </Base>
      } />
    </div>
  );
}

//<Route render={() => <NavBar {..._userProps} />} />
export default BackApp;