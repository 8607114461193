import React, { useState, useEffect } from 'react';
import axios from 'axios';
import util from 'util';
import constClass from '../../Constants/Constants';
import BackyardOrder from './BackyardOrder'
import BackyardOrderFin from './BackyardOrderFin';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
 
const BackyardOrderFinTab = (props) => {
  const statusIndex = (status) => {
    if(status === constClass.STATUS.REG){
      return 0;
    }else if(status === constClass.STATUS.FIN){
      return 1;
    } else if(status === constClass.STATUS.CCL) {
      return 2;
    } else if(status === constClass.STATUS.ABS) {
      return 3;
    }
    // if(status === constClass.STATUS.FIN){
    //   return 1;
    // } else if(status === constClass.STATUS.CCL) {
    //   return 2;
    // } else if(status === constClass.STATUS.ABS) {
    //   return 3;
    // }
  };
  const [index, setIndex] = useState(statusIndex(props.sts));
  const handleTabSelect = (idx, lastIndex, e) => {
    setIndex(idx);
    return true;
  }

  return (
    <div className="container">
      <Tabs onSelect={handleTabSelect}>
        <TabList className="nav nav-pills mb-2">
          <Tab className="nav-item"><a className={`nav-link ${index === 0 ? 'active' : ''}`} href="#">発券</a></Tab>
          <Tab className="nav-item"><a className={`nav-link ${index === 1 ? 'active' : ''}`} href="#">不在</a></Tab>
          <Tab className="nav-item"><a className={`nav-link ${index === 2 ? 'active' : ''}`} href="#">入店</a></Tab>
          <Tab className="nav-item"><a className={`nav-link ${index === 3 ? 'active' : ''}`} href="#">取消</a></Tab>
          {/* <Tab className="nav-item"><a className={`nav-link ${index === 0 ? 'active' : ''}`} href="#">完了一覧</a></Tab>
          <Tab className="nav-item"><a className={`nav-link ${index === 1 ? 'active' : ''}`} href="#">取消一覧</a></Tab>
          <Tab className="nav-item"><a className={`nav-link ${index === 2 ? 'active' : ''}`} href="#">不在一覧</a></Tab> */}
        </TabList>
        <TabPanel>
          <BackyardOrder {...props} />
        </TabPanel>
        <TabPanel>
          <BackyardOrderFin statusIdx={index} {...props} />
        </TabPanel>
        <TabPanel>
          <BackyardOrderFin statusIdx={index} {...props} />
        </TabPanel>
        <TabPanel>
          <BackyardOrderFin statusIdx={index} {...props} />
        </TabPanel>
      </Tabs>
    </div>
  )
}

export default BackyardOrderFinTab;