import React, { useState, useEffect } from 'react';
import axios from 'axios';
import util from 'util';
import constClass from '../../Constants/Constants';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { useToasts } from 'react-toast-notifications';

const BackyardCheckInFinPanel = (props) => {
  const { user, userActions } = props;
  const [orderData, setOrderData] = useState(null);
  const [lockData, setLockData] = useState(false);
  const { addToast, removeAllToasts } = useToasts();

  const refreshOrder = async () => {
    var reg_params = {};
    if (props.status === "CCL") {
      reg_params = {
        "operator": "or",
        "where": [
          {
            "status": constClass.STATUS.CCCL
          },
          {
            "status": constClass.STATUS.ACCL
          },
          {
            "status": constClass.STATUS.CCL
          },
        ]
      }
    } else if(props.status === "CHK") {
      reg_params = {
        "operator": "or",
        "where": [
          {
            "status": constClass.STATUS.CHK
          },
        ]
      }
    }
    const data = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/order/search/`, reg_params)).data;
    data.sort((a, b) => a.order_id - b.order_id);
    setOrderData(data);
  }

  const handleStatusButtonClick = async (order_id, status) => {
    if (orderData.find(o => o.order_id === order_id).status === status) {
      return;
    }
    const params = {
      status,
      user_id: [constClass.STATUS.CALL, constClass.STATUS.FIN, constClass.STATUS.CCL].includes(status) ? user.userId : null,
    };
    try {
      setLockData(true);
      await axios.put(`${process.env.REACT_APP_BACKEND_URL}/order/status/${order_id}`, params);
    }catch(err){
      if (err.response.data !== null) {
        addToast(err.response.data.msg, { appearance: 'error', autoDismiss: true });
      } else {
        addToast(err.response, { appearance: 'error', autoDismiss: true });  
      }
    } finally {
      await refreshOrder();
      setLockData(false);
    }
  }

  const renderButton = (data, status, statusName, additionalStatus = []) => {
    additionalStatus.push(status);
    statusName = [constClass.STATUS_NAME.CALL, constClass.STATUS_NAME.PRE].includes(statusName) && additionalStatus.includes(data.status) ? statusName + '中' : statusName;
    return (
      <button type="button"
        disabled={lockData}
        className={`btn ${additionalStatus.includes(data.status) ? 'btn-danger' : (constClass.ACTIVE_BUTTONS[data.status].includes(status) ? 'btn-primary' : 'btn-secondary')} mx-1`}
        onClick={() => handleStatusButtonClick(data.order_id, status)}>
        {statusName}
      </button>
    )
  }

  useEffect(() => {
    var intervalId;
    async function fetchData() {
      await refreshOrder();
      intervalId = setInterval(() => {
        refreshOrder();
      }, 5000);
      return () => {
        clearInterval(intervalId);
      };
    } 
    return fetchData();
  }, []);

  return (
    <div className="container">
      <div className="row d-none"><div className="col-12">{util.inspect(orderData)}</div></div>
      <div className="row d-none"><div className="col-12">{util.inspect(user)}</div></div>
      {orderData !== null && (
        <div className="row mb-3 p-0">
          <div className="col-12 p-0">
            <table className="table table-bordered table-striped">
              <thead className={`table-${constClass.COLOR[props.user_class]}`}>
                <tr>
                  <td className="text-center">
                    受付番号
                  </td>
                  <td className="text-center">
                    発券時刻
                  </td>
                  <td className="text-center">
                    発売/払戻
                  </td>
                  <td className="text-center">
                    支払方法
                  </td>
                  <td className="text-center">
                    種別
                  </td>
                  <td className="text-center">
                    ステータス
                  </td>
                  <td className="text-center">
                    取消
                  </td>
                </tr>
              </thead>
              <tbody className="table-scroll">
                {orderData.filter(o => o[constClass.COLUMN.CLASS] === props.user_class).map((data, idx) => (
                  <tr key={data.order_id}>
                    <td className="text-center align-middle">
                      {data.receipt_num ? data.receipt_num : '-'}
                    </td>
                    <td className="text-center align-middle">
                      {data.ins_date ? moment(data.ins_date).format('HH:mm') : '-'}
                    </td>
                    <td className="text-center align-middle">
                      {constClass.PAYMENT_NAME[data[constClass.COLUMN.PAYMENT]]}
                    </td>
                    <td className="text-center align-middle">
                      {constClass.METHOD_NAME[data[constClass.COLUMN.METHOD]]}
                    </td>
                    <td className="text-center align-middle">
                      {data.line_id === constClass.PAPER_ORDER ? '紙' : 'LINE'}
                    </td>
                    <td className="text-center align-middle">
                      {renderButton(data, constClass.STATUS.PRE, constClass.STATUS_NAME.PRE)}
                      {renderButton(data, constClass.STATUS.CHLD, constClass.STATUS_NAME.CHLD)}
                      {renderButton(data, constClass.STATUS.CABS, constClass.STATUS_NAME.CABS, [constClass.STATUS.AABS])}
                    </td>
                    <td className="text-center align-middle">
                      {renderButton(data, constClass.STATUS.CHK, constClass.STATUS_NAME.CHK)}
                      {renderButton(data, constClass.STATUS.CCCL, constClass.STATUS_NAME.CCCL, [constClass.STATUS.ACCL, constClass.STATUS.CCL])}
                    </td>
                  </tr>
                ))
                }
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  )
}

export default BackyardCheckInFinPanel;