import React, { useState, useEffect } from 'react';
import axios from 'axios';
import util from 'util';
import constClass from '../../Constants/Constants';
import { Link } from 'react-router-dom';
import BackyardCheckInPanel from './BackyardCheckInPanel';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
//import 'react-tabs/style/react-tabs.css';
import BackyardCheckInFinPanel from './BackyardCheckInFinPanel';
import BackyardCheckInCallPanel from './BackyardCheckInCallPanel';

const BackyardCheckIn = (props) => {
  const [index, setIndex] = useState(0);
  const handleTabSelect = (idx, lastIndex, e) => {
    setIndex(idx);
    return true;
  }

  return (
    <div className="container">
      <Tabs onSelect={handleTabSelect} className="react-tabs">
        <div className="tab-navs">
        <TabList className="nav nav-pills mb-2 tab-list">
          <Tab className="nav-item"><a className={`nav-link ${index === 0 ? 'active' : ''}`} href="#">バス</a></Tab>
          <Tab className="nav-item"><a className={`nav-link ${index === 1 ? 'active' : ''}`} href="#">電車</a></Tab>
          <Tab className="nav-item"><a className={`nav-link ${index === 2 ? 'active' : ''}`} href="#">バス(取消一覧)</a></Tab>
          <Tab className="nav-item"><a className={`nav-link ${index === 3 ? 'active' : ''}`} href="#">電車(取消一覧)</a></Tab>
          <Tab className="nav-item"><a className={`nav-link ${index === 4 ? 'active' : ''}`} href="#">バス(チェックイン一覧)</a></Tab>
          <Tab className="nav-item"><a className={`nav-link ${index === 5 ? 'active' : ''}`} href="#">電車(チェックイン一覧)</a></Tab>
          <Tab className="nav-item"><a className={`nav-link ${index === 6 ? 'active' : ''}`} href="#">バス(窓口一覧)</a></Tab>
          <Tab className="nav-item"><a className={`nav-link ${index === 7 ? 'active' : ''}`} href="#">電車(窓口一覧)</a></Tab>
        </TabList>
        </div>
        <TabPanel>
          <BackyardCheckInPanel user_class={constClass.CLASS.BUS} {...props} />
        </TabPanel>
        <TabPanel>
          <BackyardCheckInPanel user_class={constClass.CLASS.TRAIN} {...props} />
        </TabPanel>
        <TabPanel>
          <BackyardCheckInFinPanel user_class={constClass.CLASS.BUS} status="CCL" {...props} />
        </TabPanel>
        <TabPanel>
          <BackyardCheckInFinPanel user_class={constClass.CLASS.TRAIN} status="CCL" {...props} />
        </TabPanel>
        <TabPanel>
          <BackyardCheckInFinPanel user_class={constClass.CLASS.BUS} status="CHK" {...props} />
        </TabPanel>
        <TabPanel>
          <BackyardCheckInFinPanel user_class={constClass.CLASS.TRAIN} status="CHK" {...props} />
        </TabPanel>
        <TabPanel>
          <BackyardCheckInCallPanel user_class={constClass.CLASS.BUS} {...props} />
        </TabPanel>
        <TabPanel>
          <BackyardCheckInCallPanel user_class={constClass.CLASS.TRAIN} {...props} />
        </TabPanel>
      </Tabs>
    </div>
  )
}

export default BackyardCheckIn;