import React, { useMemo } from 'react';
import { Route } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { useDispatch, useSelector } from 'react-redux';
import DisplayLinker from '../Components/Display/DisplayLinker';
import DisplayOrderCounter from '../Components/Display/DisplayOrderCounter';
import DisplayOrder from '../Components/Display/DisplayOrder';
import { ToastProvider } from 'react-toast-notifications';
import Signage from '../Components/Display/Signage';
import SmartSignage from '../Components/Display/SmartSignage';
import Ticket from '../Components/Display/Ticket';
import OpenDataSlice from '../Slices/OpenData';
import DisplayLineNav from '../Components/Display/DisplayLineNav';

const useActions = (actions, deps) => {
  const dispatch = useDispatch();
  return useMemo(
    () => {
      if (Array.isArray(actions)) {
        return actions.map(a => bindActionCreators(a, dispatch))
      }
      return bindActionCreators(actions, dispatch)
    },
    deps ? [dispatch, ...deps] : [dispatch]
  )
};

const DisplayApp = (props) => {
  const openDataActions = useActions(OpenDataSlice.actions);
  const openData = useSelector(state => state.OpenData);

  const _openDataProps = { openData, openDataActions, ...props };
  return (
    <div className="h-100 bg-lightwhite">
      <ToastProvider>
        {/*<DisplayTitle />*/}
        <Route exact path={`${props.match.path}`} >
          <div className="d-flex bg-white align-items-stretch justify-content-center">
            <DisplayOrderCounter {..._openDataProps} />
          </div>
          <div className="d-flex bg-white align-items-stretch justify-content-center">
            {/*<DisplayLineNav {..._openDataProps}/>*/}
            <DisplayOrder  {..._openDataProps} />
          </div>
        </Route>
        <Route exact path={`${props.match.path}signage`} >
          <Signage />
        </Route>
        <Route exact path={`${props.match.path}smartsignage`} >
          <SmartSignage />
        </Route>
        <Route exact path={`${props.match.path}ticket`} >
          <Ticket />
        </Route>
        <Route exact path={`${props.match.path}linker`} >
          <DisplayLinker {..._openDataProps} />
        </Route>
      </ToastProvider>
    </div>
  );
}

export default DisplayApp;