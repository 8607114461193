import React, { PureComponent } from 'react';
// import { Link } from 'react-router-dom';
// import util from 'util';
import constClass from '../../Constants/Constants';
import moment from 'moment';
import QRCode from "qrcode.react";

class Ticket extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="print-body text-black">
        {this.props.order !== null && (
          <div>
            <div className="row border-bottom border-black mx-0">
              {/* <div className="col-12">
                <div className="mb-2 text-center text-black">
                  <h2>整理券</h2>
                </div>
              </div> */}
              <div className="col-12">
                <div className="mb-2 text-center text-black">
                  <h3>マリンワールド<br />レストラン</h3>
                </div>
              </div>
            </div>
            <div className="row border-bottom border-black mb-2 mx-0">
              <div className="col-12 text-center text-black align-self-center">
                <h4 className="mt-2 mb-0 ">受付番号</h4>
              </div>
              <div className="col-12 text-center text-black align-self-center">
                <h1 className="mt-1 font-weight-bold">{this.props.order.receipt_num} 番</h1>
              </div>
              {/*this.props.order[constClass.COLUMN.CLASS] === constClass.CLASS.BUS &&
                <div className="col-12 mb-2 text-center text-black align-self-center">
                  <QRCode value={`${process.env.REACT_APP_LINE_FRIEND_BUS_TICKET}`} />
                </div>
              }
              {this.props.order[constClass.COLUMN.CLASS] === constClass.CLASS.TRAIN &&
                <div className="col-12 mb-2 text-center text-black align-self-center">
                  <QRCode value={`${process.env.REACT_APP_LINE_FRIEND_TRAIN_TICKET}`} />
                </div>
              }
              <div className="col-12 text-left text-black align-self-center mx-2">
                <h5 className="font-weight-bold">
                  QRコードから<br />
                  LINEを友だち追加すると<br />
                  呼出し状況を確認可能です
                </h5>
            </div>*/}
            </div>
            <div className="row border-bottom border-black mb-2 mx-0">
              <div className="col-12 text-left text-black align-self-center mx-2">
                <h5 className=" ">
                  お呼出しから10分以内で<br />
                  入店されませんと不在と<br />
                  なり20分で順番待ち取り<br />
                  消しとなります。
                </h5>
              </div>
            </div>
            <div className="row mb-2 mx-0">
              <div className="col-12 text-left text-black align-self-center mx-2">
                <h5 className=" ">
                  お呼出し状況はこちら
                </h5>
              </div>
              </div>
              <div className="row border-bottom border-black mb-2 mx-0">
                <div className="col-12 mb-2 text-center text-black align-self-center">
                  <QRCode value={`${this.props.path}`} />
                </div>
              </div>          
            {/* <div className="row border-bottom border-black mb-2 mx-0">
              <div className="col-12 text-left text-black align-self-center mx-2">
                <h5 className="">
                  お呼出しの順番は<br />
                  前後する可能性が<br />
                  ございます。
                </h5>
              </div>
            </div> */}
            <div className="row mb-2 pb-2 mx-0">
              <div className="col-12 text-center text-black align-self-center">
                {/* <h4 className="">{constClass.PAYMENT_NAME[this.props.order[constClass.COLUMN.PAYMENT]]} | {constClass.CLASS_NAME[this.props.order[constClass.COLUMN.CLASS]]} | {constClass.METHOD_NAME[this.props.order[constClass.COLUMN.METHOD]]}</h4> */}
                <h4 className="">{constClass.HEADING.ADULT_SHORT}:{this.props.order[constClass.COLUMN.ADULT]} | {constClass.HEADING.CHILD_SHORT}:{this.props.order[constClass.COLUMN.CHILD]} | {constClass.HEADING.COUNTER_SHORT}:{constClass.COUNTER_NAME[this.props.order[constClass.COLUMN.COUNTER]]}</h4>
              </div>
              <div className="col-12 text-center text-black align-self-center">
                <h4 className="">{moment(this.props.order.ins_date).format('YYYY/MM/DD HH:mm:ss')}</h4>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default Ticket;


