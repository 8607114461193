import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import util from 'util';

class Title extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-12">
            <Link to={`/`}>
              <div className="mb-2 text-center text-primary">
                定期券受付申込
              </div>
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

export default Title;