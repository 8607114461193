import React, { Component } from 'react';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import constClass from '../../Constants/Constants';
import Loading from '../Loading/Loading';
import ArrowOn from '../Images/arrow_on.png';
import ArrowOff from '../Images/arrow_off.png';
import logo from '../Images/logo.svg';
import Modal from 'react-modal';
import { jwtDecode } from "jwt-decode";

Modal.setAppElement("#root");

class Order extends Component {
  constructor(props) {
    super(props);
    this.state = {
      disabled: false,
      my_order: null,
      payment: null,
      class_id: null,
      method: null,
      message: null,
      open_data: null,
      wait_count: null,
      call_data: null,
      pre_data: null,
      ready: false,
      adult_count: 1,
      child_count: 0,
      counter: null,
      language: "ja"
    };

    this.modalStyle = {
      overlay: {
        position: "fixed",
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: "rgba(0,0,0,0.5)"
      },
      content: {
        position: "absolute",
        left: '0.5rem',
        right: '0.5rem',
        top: '50%',
        bottom: 'auto',
        marginTop: '-25%',
        borderRadius: "0rem",
        padding: "0px",
        height: "auto"
      }
    };

    this.refreshOrder = this.refreshOrder.bind(this);
    this.change = this.change.bind(this);
    this.handleRequestClick = this.handleRequestClick.bind(this);
    this.setReady = this.setReady.bind(this);
  }

  async componentDidMount() {
    this.refreshOrder();
    this.refreshOrderCount();
    this.intervalCount = setInterval(() => {
      this.refreshOrderCount();
    }, 10000);
    this.disableBounceScroll();
  }

  async componentWillUnmount() {
    clearInterval(this.intervalCount);
  }

  disableBounceScroll() {
    let touchY = 0;

    document.body.addEventListener('touchstart', (e) => {
      touchY = e.touches[0].screenY;
    });

    document.body.addEventListener('touchmove', (e) => {
      let el = e.target;
      const moveY = e.touches[0].screenY;
      let noScroll = true;

      while (el !== null) {
        if (el.offsetHeight < el.scrollHeight) {
          if (touchY < moveY && el.scrollTop === 0) {
            break;
          }

          if (touchY > moveY && el.scrollTop === el.scrollHeight - el.offsetHeight) {
            break;
          }

          noScroll = false;
          break;
        }
        el = el.parentElement;
      }

      if (noScroll) {
        if (e.cancelable) {
          e.preventDefault();
        }
      }

      touchY = moveY;
    }, { passive: false });
  }

  change(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  getDisplayName(language, name) {
    if (language.includes('ja')) {
      return constClass.JA_DISPLAY_NAME[name];
    } else if (language.includes('en')) {
      return constClass.EN_DISPLAY_NAME[name];
    } else if (language === 'zh-CN' || language === 'zh-Hans') {
      return constClass.ZHHANS_DISPLAY_NAME[name];
    } else if (language === 'zh-TW' || language === 'zh-Hant' || language === 'zh-HK') {
      return constClass.ZHHANT_DISPLAY_NAME[name];
    } else if (language.includes('ko')) {
      return constClass.KO_DISPLAY_NAME[name];
    } else {
      return constClass.EN_DISPLAY_NAME[name];
    }
  };

  getCountTime(person) {
    if (person === 0) {
      return 0;
    } else if (person <= 30) {
      return 40;
    } else if (person <= 40) {
      return 50;
    } else if (person <= 50) {
      return 60;
    } else if (person <= 60) {
      return 80;
    } else if (person <= 70) {
      return 90;
    } else if (person <= 80) {
      return 100;
    } else if (person <= 110) {
      return 120;
    } else if (person <= 120) {
      return 130;
    } else if (person <= 150) {
      return 150;
    } else {
      return 150;
    }
  };

  async refreshOrder() {
    if (process.env.REACT_APP_ENV !== 'dev' && window.liff.isInClient()) {
      await window.liff.ready;
    }
    if (!this.props.liff_access_token) {
      if (!this.intervalOrder) {
        this.intervalOrder = setInterval(() => {
          this.refreshOrder();
        }, 500);
      }
    } else {
      if (this.props.liff_access_token === 'token_unusable') {
        clearInterval(this.intervalOrder);
        const jwt1 = localStorage.getItem('jwt1');
        if (jwt1 === null) {
          const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/jwt/issue/`);
          localStorage.setItem('jwt1', response.data.jwt);
          this.setState({
            my_order: undefined,
            disabled: false,
            language: window.liff.getAppLanguage()
          });
          var page;
          if (this.state.my_order === undefined) { // 未受付
            page = constClass.REQUEST;
          } else if (this.props.page === constClass.CANCEL) { // 受付済かつキャンセル
            page = this.props.page;
          } else { // 受付済かつキャンセル以外
            page = null;
          }
          this.props.setPage(page);
          if (this.props.page === constClass.CANCEL) {
            this.submitCancel('CCL');
          }
          this.setReady();
        } else {
          const reg_params = {
            "operator": "and",
            "where": [
              {
                "operator": "notIn",
                "attr": "status",
                "val": [constClass.STATUS.FIN, constClass.STATUS.UCCL, constClass.STATUS.ACCL, constClass.STATUS.CCL]
              }
            ]
          }
          const my_order = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/order/jwt/search/`, reg_params, {
            headers: {
              Authorization: `Bearer ${jwt1}`
            }
          })).data;
          this.setState({
            my_order: my_order.find(o => o.line_id == 'PAPER_ORDER'),
            disabled: false,
            language: window.liff.getAppLanguage()
          });
          var page;
          if (this.state.my_order === undefined) { // 未受付
            page = constClass.REQUEST;
          } else if (this.props.page === constClass.CANCEL) { // 受付済かつキャンセル
            page = this.props.page;
          } else { // 受付済かつキャンセル以外
            page = null;
          }
          this.props.setPage(page);
          if (this.props.page === constClass.CANCEL) {
            this.submitCancel('CCL');
          }
          this.setReady();
        }
      } else {
        clearInterval(this.intervalOrder);
        const reg_params = {
          "operator": "and",
          "where": [
            {
              "operator": "notIn",
              "attr": "status",
              "val": [constClass.STATUS.FIN, constClass.STATUS.UCCL, constClass.STATUS.ACCL, constClass.STATUS.CCL]
            },
            {
              "line_id": this.props.liff_access_token
            }
          ]
        }
        const my_order = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/order/search/`, reg_params)).data;
        this.setState({
          my_order: my_order.find(o => o.line_id == this.props.liff_user_id),
          disabled: false,
          language: window.liff.getAppLanguage()
        });
        var page;
        if (this.state.my_order === undefined) { // 未受付
          page = constClass.REQUEST;
        } else if (this.props.page === constClass.CANCEL) { // 受付済かつキャンセル
          page = this.props.page;
        } else { // 受付済かつキャンセル以外
          page = null;
        }
        this.props.setPage(page);
        if (this.props.page === constClass.CANCEL) {
          this.submitCancel('CCL');
        }
        this.setReady();
      }
    }
  }

  async refreshOrderCount() {
    if (process.env.REACT_APP_ENV !== 'dev' && window.liff.isInClient()) {
      await window.liff.ready;
    }
    const data = (await axios.get(`${process.env.REACT_APP_BACKEND_URL}/order/count/`)).data;
    // var bus_pre = data.pre_data.filter(p => p.class === constClass.CLASS.BUS);
    // var bus_blank = new Array(10 - (bus_pre ? bus_pre.length : 0)).fill({});
    // var train_pre = data.pre_data.filter(p => p.class === constClass.CLASS.TRAIN);
    // var train_blank = new Array(10 - (train_pre ? train_pre.length : 0)).fill({});
    var abs_data;
    if (data.abs_data && data.abs_data.length <= constClass.MAX_ABS) {
      var abs_blank = new Array(constClass.MAX_ABS - (data.abs_data ? data.abs_data.length : 0)).fill({});
      abs_data = data.abs_data.concat(abs_blank);
    } else {
      abs_data = data.abs_data.filter((dat, idx) => idx < constClass.MAX_ABS);
    }
    var call_data;
    if (data.call_data && data.call_data.length <= constClass.MAX_CALL) {
      var call_blank = new Array(constClass.MAX_CALL - (data.call_data ? data.call_data.length : 0)).fill({});
      call_data = data.call_data.concat(call_blank);
    } else {
      call_data = data.call_data.filter((dat, idx) => idx < constClass.MAX_CALL);
    }
    this.setState({
      wait_count: data.wait_count,
      call_data: call_data,
      abs_data: abs_data,
      open_data: data.open_data
    });
    this.setReady();
  }

  setReady() {
    if (this.state.my_order !== null &&
      this.state.wait_count !== null &&
      this.state.call_data !== null &&
      this.state.abs_date !== null &&
      this.state.open_data !== null) {
      this.setState({ ready: true });
    } else {
      this.setState({ ready: false });
    }
  }

  submitPayment(payment) {
    this.setState({ payment, message: null });
  }

  submitClass(class_id) {
    this.setState({ class_id, message: null });
  }

  submitMethod(method) {
    this.setState({ method, message: null });
  }

  addAdultCount() {
    var count = this.state.adult_count + 1;
    console.log(count);
    this.setState({ adult_count: count });
  }

  addChildCount() {
    var count = this.state.child_count + 1;
    this.setState({ child_count: count });
  }

  subAdultCount() {
    var count = (this.state.adult_count - 1 < 1 ? 1 : this.state.adult_count - 1); // 大人は0人に減らせないように変更
    console.log(count);
    this.setState({ adult_count: count });
  }

  subChildCount() {
    var count = (this.state.child_count - 1 < 0 ? 0 : this.state.child_count - 1);
    this.setState({ child_count: count });
  }

  submitCounter(counter) {
    this.setState({ counter });
  }

  checkDataInvalid() {
    if (this.state.adult_count === null || this.state.child_count === null || this.state.counter === null) {
      return true;
    } else {
      return false;
    }
  }

  async submit() {
    if (this.checkDataInvalid() || this.state.disabled) {
      return;
    }
    if (window.confirm(`${this.getDisplayName(this.state.language, 'SUBMIT2')}`)) {//\r\n${constClass.PAYMENT_NAME[this.state.payment]}・${constClass.CLASS_NAME[this.state.class_id]}・${constClass.METHOD_NAME[this.state.method]}
      this.setState({ disabled: true });
      if (this.props.liff_access_token === 'token_unusable') {
        const jwt1 = localStorage.getItem('jwt1');
        var data_jwt = {
          'shop_id': constClass.SHOP_ID,
          'line_id': 'PAPER_ORDER',
        };
        data_jwt[constClass.COLUMN.ADULT] = this.state.adult_count;
        data_jwt[constClass.COLUMN.CHILD] = this.state.child_count;
        data_jwt[constClass.COLUMN.COUNTER] = this.state.counter;
        try {
          const my_order_jwt = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/order/jwt`, data_jwt, {
            headers: {
              Authorization: `Bearer ${jwt1}`
            }
          })).data;
          this.setState({ message: `${this.getDisplayName(this.state.language, 'SUBMIT1')}`, adult_count: 1, child_count: 0, counter: null });
        } catch (err) {
          this.setState({ message: err });
        }
        this.refreshOrder();
        if (window.liff.isInClient()) {
          window.liff.closeWindow();
        }
      }
      else {
        var data = {
          'shop_id': constClass.SHOP_ID,
          'line_id': this.props.liff_access_token,
        };
        data[constClass.COLUMN.ADULT] = this.state.adult_count;
        data[constClass.COLUMN.CHILD] = this.state.child_count;
        data[constClass.COLUMN.COUNTER] = this.state.counter;
        try {
          const my_order = (await axios.post(`${process.env.REACT_APP_BACKEND_URL}/order/`, data)).data;
          this.setState({ message: `${this.getDisplayName(this.state.language, 'SUBMIT1')}`, adult_count: 1, child_count: 0, counter: null });
        } catch (err) {
          this.setState({ message: err });
        }
      }
      this.refreshOrder();
      if (window.liff.isInClient()) {
        window.liff.closeWindow();
      }
    }
  }

  async submitCancel(command = null) {
    if (this.state.my_order === null || this.state.my_order === undefined || this.state.disabled) {
      return;
    }
    if (this.props.liff_access_token === 'token_unusable') {
      if (window.confirm(`${this.getDisplayName(this.state.language, 'CANCEL1')}\r\n${this.getDisplayName(this.state.language, 'CANCEL3')}：${this.state.my_order.receipt_num}${this.state.language === 'ja' ? '番' : ''}`)) {
        this.setState({ disabled: true });
        const jwt1 = localStorage.getItem('jwt1');
        var data_jwt = {
          status: constClass.STATUS.UCCL,
          'line_id': 'PAPER_ORDER',
        };
        try {
          await axios.put(`${process.env.REACT_APP_BACKEND_URL}/order/jwt/status/${this.state.my_order.order_id}`, data_jwt, {
            headers: {
              Authorization: `Bearer ${jwt1}`
            }
          });
          this.setState({ message: `${this.getDisplayName(this.state.language, 'CANCEL2')}`, adult_count: 1, child_count: 0, counter: null })
        } catch (err) {
          this.setState({ message: err });
        }
        this.props.setPage(constClass.REQUEST);
        this.refreshOrder();
        if (window.liff.isInClient()) {
          window.liff.closeWindow();
        }
      } else {
        if (command === 'CCL') {
          if (window.liff.isInClient()) {
            window.liff.closeWindow();
          }
        }
      }
    } else {
      if (window.confirm(`${this.getDisplayName(this.state.language, 'CANCEL1')}\r\n${this.getDisplayName(this.state.language, 'CANCEL3')}：${this.state.my_order.receipt_num}${this.state.language === 'ja' ? '番' : ''}`)) {
        this.setState({ disabled: true });
        const data = {
          status: constClass.STATUS.UCCL,
          'line_id': this.props.liff_access_token,
        };
        try {
          await axios.put(`${process.env.REACT_APP_BACKEND_URL}/order/status/${this.state.my_order.order_id}`, data);
          this.setState({ message: `${this.getDisplayName(this.state.language, 'CANCEL2')}`, adult_count: 1, child_count: 0, counter: null })
        } catch (err) {
          this.setState({ message: err });
        }
        this.props.setPage(constClass.REQUEST);
        this.refreshOrder();
        if (window.liff.isInClient()) {
          window.liff.closeWindow();
        }
      } else {
        if (command === 'CCL') {
          if (window.liff.isInClient()) {
            window.liff.closeWindow();
          }
        }
      }
    }
  }

  handleRequestClick() {
    this.props.setPage(constClass.REQUEST);
  }

  renderHeader() {
    return (
      <header className="header">
        <div className="row mx-0">
          <div className="col section section-top">
          <span>{this.state.my_order !== undefined ? this.getDisplayName(this.state.language, 'HEADER3') :
              this.props.page === constClass.REQUEST ? this.getDisplayName(this.state.language, 'HEADER1') : this.getDisplayName(this.state.language, 'HEADER2')}</span>
          </div>
        </div>
      </header>
    );
  }

  renderReceipt() {
    var text_class = `text-${constClass.COLOR.BUS}`;
    var border_class = `border-${constClass.COLOR.BUS}-bold`;
    return (
      <div className="row mx-0 my-5">
        <div className="col">
          <div className="row">
            <div className="col text-center">
              <h4>{this.getDisplayName(this.state.language, 'RECEIPT1')}</h4>
            </div>
          </div>
          <div className="row p-1 justify-content-center">
            <div className={`col-auto text-center ${text_class} mx-1 py-1 w-50 bg-white border ${border_class}`}>
              <h4 className="mb-0 font-weight-bold">{this.state.my_order.receipt_num}</h4>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderSection() {
    return (
      <div className="row mx-0">
        <div className="col section">
          <span>{this.getDisplayName(this.state.language, 'HEADER2')}</span>
        </div>
      </div>
    );
  }

  renderCount() {
    return (
      <div className="row mx-0 my-2 px-3-env">
        <div className="col">
          <div className="row">
            <div className="col text-center">
              <span>{this.getDisplayName(this.state.language, 'COUNT1')}</span>
            </div>
          </div>
          <div className="row p-1">
            <div className="col text-center ml-2 mr-1">
              <div className={`row border border-${constClass.COLOR.BUS} bg-${constClass.COLOR.BUS}`}>
                <div className="col bg-white px-0 py-1">
                  <h5 className="mb-0 d-inline font-weight-bold">{this.state.wait_count.find(x => true) ? this.state.wait_count.find(x => true).count_order : 0}</h5>
                  <span className="d-inline small">{this.getDisplayName(this.state.language, 'COUNT2')}</span>
                </div>
              </div>
            </div>
            <div className="col text-center ml-2 mr-1">
              <div className={`row border border-${constClass.COLOR.BUS} bg-${constClass.COLOR.BUS}`}>
                <div className="col bg-white px-0 py-1">
                  <span className="d-inline small">{this.getDisplayName(this.state.language, 'COUNT3')}</span>
                  <h5 className="mb-0 d-inline font-weight-bold">{this.state.wait_count.find(x => true) ? this.getCountTime(Number(this.state.wait_count.find(x => true).count_order)) : 0}</h5>
                  <span className="d-inline small">{this.getDisplayName(this.state.language, 'COUNT4')}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col text-center">
              <span>{this.getDisplayName(this.state.language, 'COUNT5')}</span>
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderAbs() {
    var bg_class = `bg-${constClass.COLOR.BUS}`;
    var text_class = `text-${constClass.COLOR.BUS}`;
    // var data;
    // var icon;
    // if (class_id === constClass.CLASS.BUS) {
    //   data = this.state.pre_data.bus;
    //   icon = IconBus;
    // } else if (class_id === constClass.CLASS.TRAIN) {
    //   data = this.state.pre_data.train;
    //   icon = IconTrain;
    // }
    return (
      <div className="px-0-env">
        {/* <div className="row mx-1 my-1">
          <div className={`col text-center ${bg_class} py-1 mx-0`}>
            <span className="align-middle">不在の方</span>
          </div>
        </div> */}
        <div className="row mx-1 my-1">
          {this.state.abs_data.map((p, idx) => (
            idx < (constClass.MAX_ABS / 2) ? (
              <div className={`col text-center ${text_class} px-1`} key={idx}>
                <div className={`w-100 text-center ${text_class} m-1 border border-cell`} key={idx}>
                  <span className="mb-0 ">{p.receipt_num ? p.receipt_num : '-'}</span>
                </div>
              </div>
            ) : null
          ))}
        </div><div className="row mx-1 my-1">
          {this.state.abs_data.map((p, idx) => (
            idx >= (constClass.MAX_ABS / 2) ? (
              <div className={`col text-center ${text_class} px-1`} key={idx}>
                <div className={`w-100 text-center ${text_class} m-1 border border-cell`} key={idx}>
                  <span className="mb-0 ">{p.receipt_num ? p.receipt_num : '-'}</span>
                </div>
              </div>
            ) : null
          ))}
        </div>
      </div>
    );
  }

  renderCall(class_id) {
    var bg_class = `bg-${constClass.COLOR.BUS}`;
    var text_class = `text-${constClass.COLOR.BUS}`;
    return (
      <div className="px-0-env">
        <div className="row mx-1 my-1">
          {this.state.call_data.map((p, idx) => (
            idx < (constClass.MAX_CALL / 2) ? (
              <div className={`col text-center ${text_class} px-1`} key={idx}>
                <div className={`w-100 text-center ${text_class} m-1 border border-cell`} key={idx}>
                  <span className="mb-0 ">{p.receipt_num ? p.receipt_num : '-'}</span>
                </div>
              </div>
            ) : null
          ))}
        </div>
        <div className="row mx-1 my-1">
          {this.state.call_data.map((p, idx) => (
            idx >= (constClass.MAX_CALL / 2) ? (
              <div className={`col text-center ${text_class} px-1`} key={idx}>
                <div className={`w-100 text-center ${text_class} m-1 border border-cell`} key={idx}>
                  <span className="mb-0 ">{p.receipt_num ? p.receipt_num : '-'}</span>
                </div>
              </div>
            ) : null
          ))}
        </div>
      </div>
    );
  }

  renderCounter() {
    return (
      <div className="row mx-0">
        <div className="col px-0">
          <div className="row mx-0">
            <div className="col section-dark px-0">
              <p className="my-0">{this.getDisplayName(this.state.language, 'COUNTER1')}</p>
            </div>
            <div className="col-12 py-2 text-white bg-white">
              {this.renderCall()}
            </div>
          </div>
          <div className="row mx-0">
            <div className="col section-dark px-0">
              <p className="my-0">{this.getDisplayName(this.state.language, 'COUNTER2')}</p>
            </div>
            <div className="col-12 text-white bg-white py-2">
              {this.renderAbs()}
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderRequest() {
    return (
      <div className="row mx-0">
        <div className="col px-0">
          <div className="row mx-0">
            <div className="col section-dark py-0 line-height-2-2">
              <span className="mb-0 d-inline-block font-weight-bold align-middle pr-1 font-h4">&#10102;</span>
              <span className="d-inline-block align-middle">{this.getDisplayName(this.state.language, 'REQUEST1')}</span>
            </div>
          </div>
          <div className="row mx-0 bg-white px-3-env pt-2 pb-2">
            <div className="col-auto text-center bg-lightwhite p-1 ml-1">
              <button
                disabled={this.state.disabled}
                className={`btn btn-active w-100 h-100 py-2`}
                onClick={() => { this.subAdultCount() }}>
                －
                </button>
            </div>
            <div className="col text-center bg-lightwhite py-2 px-1 align-self-center">
              <div className="bg-white font-h4">{this.state.adult_count}</div>
            </div>
            <div className="col-auto text-center bg-lightwhite p-1 mr-1">
              <button
                disabled={this.state.disabled}
                className={`btn btn-active w-100 h-100 py-2`}
                onClick={() => { this.addAdultCount() }}>
                ＋
                </button>
            </div>
          </div>
          <div className="row mx-0">
            <div className="col section-dark py-0 line-height-2-2">
              <span className="mb-0 d-inline-block font-weight-bold align-middle pr-1 font-h4">&#10103;</span>
              <span className="d-inline-block align-middle">{this.getDisplayName(this.state.language, 'REQUEST2')}</span>
            </div>
          </div>
          <div className="row mx-0 bg-white px-3-env pt-2 pb-2">
            <div className="col-auto text-center bg-lightwhite p-1 ml-1">
              <button
                disabled={this.state.disabled}
                className={`btn btn-active w-100 h-100 py-2`}
                onClick={() => { this.subChildCount() }}>
                －
                </button>
            </div>
            <div className="col text-center bg-lightwhite py-2 px-1 align-self-center">
              <div className="bg-white font-h4">{this.state.child_count}</div>
            </div>
            <div className="col-auto text-center bg-lightwhite p-1 mr-1">
              <button
                disabled={this.state.disabled}
                className={`btn btn-active w-100 h-100 py-2`}
                onClick={() => { this.addChildCount() }}>
                ＋
                </button>
            </div>
          </div>
          <div className="row mx-0">
            <div className="col section-dark py-0 line-height-2-2">
              <span className="mb-0 d-inline-block font-weight-bold align-middle pr-1 font-h4">&#10104;</span>
              <span className="d-inline-block align-middle">{this.getDisplayName(this.state.language, 'REQUEST3')}</span>
            </div>
          </div>
          <div className="row mx-0 bg-white px-3-env pt-2 pb-2">
            <div className="col text-center bg-lightwhite p-1 ml-1">
              <button
                disabled={this.state.disabled}
                className={`btn ${this.state.counter === constClass.COUNTER.TABLE ? "btn-active" : "btn-enable"} w-100 h-100 py-2`}
                onClick={() => { this.submitCounter(constClass.COUNTER.TABLE) }}>
                {this.getDisplayName(this.state.language, 'REQUEST4')}
              </button>
            </div>
            <div className="col text-center bg-lightwhite py-1 pr-1 pl-0 mr-1">
              <button
                disabled={this.state.disabled}
                className={`btn ${this.state.counter === constClass.COUNTER.COUNTER ? "btn-active" : "btn-enable"} w-100 h-100 py-2`}
                onClick={() => { this.submitCounter(constClass.COUNTER.COUNTER) }}>
                {this.getDisplayName(this.state.language, 'REQUEST5')}
              </button>
            </div>
          </div>
          <div className="row mx-0">
            <div className="col section-dark py-0 line-height-2-2">
              <span className="mb-0 d-inline-block font-weight-bold align-middle pr-1 font-h4">&#10105;</span>
              <span className="d-inline-block align-middle">{this.getDisplayName(this.state.language, 'REQUEST6')}</span>
            </div>
          </div>
          <div className="col mx-0 bg-white px-3-env pt-2 pb-2 text-center">
            <div>{this.getDisplayName(this.state.language, 'REQUEST7')}</div>
          </div>
        </div>
      </div>
    );
  }

  renderBottom() {
    return (
      <footer className="footer text-center">
        <div className="container m-0 p-0 mw-100">
          <div className="row mx-0">
            {(this.state.my_order !== undefined) && (
              <div className="col text-center p-0">
                <div className="row mx-0">
                  <div className="col text-center p-0">
                    <button
                      disabled={this.state.disabled}
                      className="btn-lg btn-submit-cancel w-100 py-3 p-env-bottom"
                      onClick={() => { this.submitCancel() }}>
                      {this.getDisplayName(this.state.language, 'BOTTOM2')}
                    </button>
                  </div>
                </div>
              </div>
            )}
            {(this.state.my_order === undefined) && (
              <div className="col text-center p-0">
                <div className="row mx-0">
                  <div className="col text-center p-0">
                    <button
                      disabled={this.checkDataInvalid() || this.state.disabled}
                      className={`btn-lg btn-submit w-100 py-3 p-env-bottom`}
                      onClick={() => { this.submit() }}>
                      {this.getDisplayName(this.state.language, 'BOTTOM1')} <img className="arrow mb-1" src={this.checkDataInvalid() || this.state.disabled ? ArrowOff : ArrowOn} alt="" />
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </footer>
    );
  }

  render() {
    return (
      this.state.ready ? <div className="liff-top bg-lightwhite font-weight-bold">
        {this.renderHeader()}
        <div className={`page-${(this.state.my_order !== undefined || this.props.page === constClass.REQUEST) ? "btn" : "full"}`}>
          {this.state.my_order === undefined && this.renderCount()}
          {this.state.my_order !== undefined && this.renderReceipt()}
          {this.state.my_order !== undefined && this.renderSection()}
          {(this.state.my_order !== undefined || this.props.page !== constClass.REQUEST) && this.renderCounter()}
          {(this.state.my_order === undefined && this.props.page === constClass.REQUEST) && this.renderRequest()}
          {(this.state.my_order !== undefined || this.props.page === constClass.REQUEST) && this.renderBottom()}
        </div>
        {this.state.disabled && <Loading />}
        <Modal isOpen={false} style={this.modalStyle}>
          <div className="text-center section m-0 py-2">
            <span>現在受付時間外です。</span><br />
            LINEでの受付時間は以下の通りとなります<br />
            平日：7:00～19:00<br />
            土曜：9:00～17:00<br />
            日祝日：9:00～17:00
          </div>
        </Modal>
        <Modal isOpen={this.state.my_order === undefined && this.props.page === constClass.REQUEST && this.state.open_data.open_wait_flg === constClass.CLOSE} style={this.modalStyle}>
          <div className="text-center section-white m-0 py-2">
            <h4>すぐにご入店いただけます</h4>
            <span>ご来店ありがとうございます</span><br /><br />
            <img className="w-50" src={logo} />
          </div>
        </Modal>
      </div>
        : <Loading />)
  }
}

export default withRouter(Order);